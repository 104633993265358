import $ from 'jquery';
global.$ = global.jQuery = $;


require('datatables.net-bs');
require('bootstrap');
require('jquery-ui');
require('jquery-confirm');
require('jquery-validation');
require('select2');
require('script-loader!jquery-form-validator/form-validator/jquery.form-validator.min');
require('eonasdan-bootstrap-datetimepicker');
require('blueimp-file-upload');
require('moment');

$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');
global.Translator = require('bazinga-translator');